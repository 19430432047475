import type { TUrl } from 'app.types';
import cn from 'classnames';
import Link from 'next/link';
import type {
  FC,
  HTMLAttributeAnchorTarget,
  HTMLAttributes,
  MouseEventHandler,
} from 'react';

import s from 'components/UIKit/ALink/ALink.module.scss';

export interface IALinkProps extends HTMLAttributes<HTMLAnchorElement> {
  href: TUrl;
  target?: HTMLAttributeAnchorTarget;
  rel?: string;
  onClick?: MouseEventHandler;
  dataTestId?: string;
}

export const ALink: FC<IALinkProps> = props => {
  const {
    href,
    children,
    className,
    target = '_self',
    rel,
    onClick,
    dataTestId = 'link',
  } = props;
  return (
    <Link
      href={href}
      onClick={onClick}
      className={cn([s.link, className])}
      target={target}
      rel={rel}
      data-testid={dataTestId}
    >
      {children}
    </Link>
  );
};
