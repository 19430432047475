import Head from 'next/head';
import { useRouter } from 'next/router';
import type { FC } from 'react';

import {
  defaultDescription,
  defaultKeywords,
  defaultOgDescription,
  defaultTitle,
} from './constants';

interface IHtmlHeadProps {
  title?: string;
  description?: string;
  keywords?: string;
}

const isProd = process.env.NODE_ENV === 'production';

export const HtmlHead: FC<IHtmlHeadProps> = props => {
  const {
    title = defaultTitle,
    description = defaultDescription,
    keywords = defaultKeywords,
  } = props;
  const router = useRouter();

  const canonicalUrl = `https://selfpub.ru${router.asPath}`.replace(/\?.*/, '');

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, user-scalable=no"
      />
      <link key="canonical" rel="canonical" href={canonicalUrl} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta httpEquiv="msthemecompatible" content="no" />

      {isProd ? (
        <>
          <link rel="icon" type="image/x-icon" href="/favicon.ico" />
          <link
            rel="icon"
            sizes="192x192"
            href="/favicons/favicon-192x192.png"
          />
          <link rel="apple-touch-icon" href="/favicons/favicon-192x192.png" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/favicons/favicon-180x180.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicons/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicons/favicon-16x16.png"
          />
          <link rel="manifest" href="/favicons/manifest.json" />
          <link rel="apple-touch-icon" href="/favicons/apple-touch-icon.png" />
        </>
      ) : (
        <link rel="icon" sizes="140x140" href="/favicons/favicon-dev.png" />
      )}
      <meta name="format-detection" content="telephone=no" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="twitter:title" content="Литрес Самиздат" />
      <meta name="twitter:image" content="/og/og-1200x600.png" />
      <meta
        name="twitter:description"
        content={description || defaultOgDescription}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta key="og:url" property="og:url" content={canonicalUrl} />
      <meta property="og:title" content="Литрес Самиздат" />
      <meta
        property="og:description"
        content={description || defaultOgDescription}
      />
      <meta property="og:image" content="/og/og-1200x600.png" />
      <meta property="og:image:alt" content="SelfPub" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:locale" content="ru_RU" />
      <meta name="robots" content="index,follow" />
      <meta name="googlebot" content="index,follow" />
    </Head>
  );
};
